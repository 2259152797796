import { Button } from 'components/buttons/Button/Button';
import NewDropdown from 'components/Dropdown';
import HeaderButton from '../HeaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { changeCountry, changeLanguage, selectLanguageState, setLanguages } from 'store/slices/languageSlice';
import React from 'react';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { getLanguages } from 'api/Languages';
import CountrySelect from 'components/ContryAutocompleteDropdown';
import { setDeterminatePopupIsShowed } from 'store/helpers';
import { INT_LANGUAGES, INTERNATIONAL_DATA } from 'api/constants';


const { language: INT_LANGUAGE} = INTERNATIONAL_DATA;

const CountrySelectorMenu = ({ onClose }) => {
    const dispatch = useDispatch();

    const { country, language, languages } = useSelector(selectLanguageState);
    const { menu_change_country_text, menu_available_languages_text, menu_save_choice_text } =
        useSelector(selectTranslations);

    const [state, setState] = React.useState({ language, country });

    const onChangeLanguage = language => setState({ ...state, language });
    const onChangeCountry = country => {
        
        setState({ ...state, country});
        try {
            getLanguages(country.code).then(response => {
                const langData = response.data.data;
                const isNotLocalize = !Array.isArray(langData) || langData.length <= 1;
                const language = isNotLocalize ? INT_LANGUAGE : langData[0];
                const dispatchLangData = isNotLocalize ? INT_LANGUAGES : response.data.data;

                dispatch(setLanguages(dispatchLangData));
                setState(prev => ({ ...prev, language }));
            });
        } catch (e) {
            return false;
        }
    };

    const handleOnSave = () => {
        if (state?.country) {
            try {
                dispatch(changeLanguage(state.language));
                dispatch(changeCountry(state?.country));
                setDeterminatePopupIsShowed();
            } finally {
                onClose();
                window.location.href = '/';
            }
        }
    };

    return (
        <div className="country-selector-popup">
            <div className="country-selector-popup__title">{menu_change_country_text}</div>
            <HeaderButton onClick={onClose} size="small" />
            <div className="country-selector-controls">
                <CountrySelect value={state.country} onChange={onChangeCountry} />
                {/* <NewDropdown
                    label={menu_choose_country_text}
                    selectedValue={state.country}
                    options={countries}
                    onChange={onChangeCountry}
                /> */}
                <NewDropdown
                    label={menu_available_languages_text}
                    selectedValue={state.language}
                    options={languages}
                    onChange={onChangeLanguage}
                />
                <Button onClick={handleOnSave} type="default">
                    {menu_save_choice_text}
                </Button>
            </div>
        </div>
    );
};

export default CountrySelectorMenu;
