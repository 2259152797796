import { createAsyncThunk } from '@reduxjs/toolkit';
import { INT_COUNTRY_CODE } from 'api/constants';
import Cookies from 'js-cookie';

export const fetchWithLanguage = (name, request, condition) =>
    createAsyncThunk(
        name,
        async (params, { getState }) => {
            const { languageState } = getState();

            const response = await request({
                language: languageState.language.code,
                country: languageState.isNotLocalize ? INT_COUNTRY_CODE : languageState.country.code,

                ...params,
            }).catch(e => {
                if (e.response.status == 404) {
                    window.location = '/404';
                }
            });

            return response.data;
        },
        { condition },
    );

export const determinate_popup_is_showed = 'determinate_popup_is_showed';

export const setDeterminatePopupIsShowed = () => {
    // const time = new Date(new Date().getTime() + 2 * 60 * 1000); // 2 minutes for test

    Cookies.set(determinate_popup_is_showed, 'true', {
        // expires: time,
        expires: 7,
    });
};
